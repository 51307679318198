.header {
  cursor: pointer;
  user-select: none;
}

.content {
  max-height: 0;
  overflow: hidden;
}

.accordion.expanded .content {
  max-height: inherit;
}