/**
 * Do not edit directly
 * Generated on Wed, 08 Mar 2023 17:26:35 GMT
 */

.themeWrapper {
  --size-constant-corners-2xl: 1rem;
  --size-constant-corners-md: 0.375rem;
  --size-constant-corners-sm: 0.125rem;
  --size-constant-corners-none: 0rem;
  --size-constant-text-2xl: 3rem;
  --size-constant-text-xl: 2rem;
  --size-constant-text-lg: 1.5rem;
  --size-constant-text-md: 1rem;
  --size-constant-text-sm: 0.5rem;
  --font-constant: sans-serif;
  --color-constant-transparent: rgba(0, 0, 0, 0);
  --color-constant-magenta: #ff00ff;
  --color-constant-black-transparent-75: rgba(0, 0, 0, 0.76);
  --color-constant-black-transparent-15: rgba(0, 0, 0, 0.16);
  --color-constant-black-full: #000000;
  --color-constant-gray-98: #fafafa;
  --color-constant-gray-93: #ededed;
  --color-constant-gray-80: #cccccc;
  --color-constant-gray-60: #989898;
  --color-constant-gray-40: #666666;
  --color-constant-gray-23: #3a3a3a;
  --color-constant-gray-20: #333333;
  --color-constant-blue-storm-dark: #0c293a;
  --color-constant-blue-storm: #0b496b;
  --color-constant-blue-sky: #4ea2e3;
  --color-constant-blue-ocean: #2081e2;
  --color-constant-green-med: #4ca849;
  --color-constant-green-light: #5ec07e;
  --color-constant-red-tomato: #ff6347;
  --color-constant-red-med: #d87168;
  --color-constant-red-alt-light: #da7d75;
  --color-constant-red-light: #ef847b;
  --color-constant-white: #ffffff;
  --breakpoint-constant-2xl: 1440;
  --variant-error-inverted: var(--color-constant-white);
  --variant-error-secondary: var(--color-constant-red-tomato);
  --variant-error-primary: var(--color-constant-red-light);
  --variant-disabled-tertiary: var(--color-constant-gray-80);
  --variant-disabled-secondary: var(--color-constant-gray-60);
  --variant-disabled-primary: var(--color-constant-gray-93);
  --variant-default-color-tertiary: var(--color-constant-blue-sky);
  --variant-default-color-secondary: var(--color-constant-red-alt-light);
  --variant-default-color-primary-inverted: var(--color-constant-white);
  --variant-default-color-primary: var(--color-constant-red-light);
  --color-text-accent: var(--color-constant-red-med);
  --color-text-secondary: var(--color-constant-gray-40);
  --color-text-default: var(--color-constant-gray-20);
  --color-text-disabled: var(--color-constant-gray-60);
  --color-text-invert-primary: var(--color-constant-white);
  --color-text-invalid: var(--color-constant-red-tomato);
  --color-text-active: var(--color-constant-gray-23);
  --color-text-link: var(--color-constant-blue-ocean);
  --color-text-success: var(--color-constant-green-med);
  --color-text-primary: var(--color-constant-red-light);
  --color-border-subtle: var(--color-constant-black-transparent-15);
  --color-border-success: var(--color-constant-green-light);
  --color-border-invalid: var(--color-constant-red-tomato);
  --color-border-disabled: var(--color-constant-gray-80);
  --color-border-invert: var(--color-constant-white);
  --color-border-primary: var(--color-constant-red-light);
  --color-bg-transparent: var(--color-constant-black-transparent-75);
  --color-bg-success: var(--color-constant-green-light);
  --color-bg-light: var(--color-constant-gray-98);
  --color-bg-disabled: var(--color-constant-gray-93);
  --color-bg-base: var(--color-constant-white);
  --color-bg-exciting: var(--color-constant-blue-sky);
  --color-bg-default: var(--color-constant-red-light);
  --color-bg-connect: var(--color-constant-red-light);
  --color-bg-primary-subtle: var(--color-constant-red-alt-light);
  --color-bg-primary: var(--color-constant-red-light);
  --component-control-border-radius: var(--size-constant-corners-2xl);
  --component-control-border-color-inverted: var(--color-constant-transparent);
  --component-control-font-size: var(--size-constant-text-md);
  --component-control-border-color: var(--variant-default-color-primary);
  --component-control-bg-disabled: var(--variant-disabled-primary);
  --component-control-bg-tertiary: var(--variant-default-color-tertiary);
  --component-control-bg-secondary: var(--variant-default-color-secondary);
  --component-control-bg-primary: var(--variant-default-color-primary);
  --component-control-color-disabled: var(--variant-disabled-secondary);
  --component-control-color-primary-inverted: var(--variant-default-color-primary-inverted);
  --component-control-color-primary: var(--variant-default-color-primary);
  --component-button-border-radius: var(--component-control-border-radius);
  --component-button-border-color-inverted: var(--component-control-border-color-inverted);
  --component-button-font-size: var(--component-control-font-size);
  --component-button-border-color: var(--component-control-border-color);
  --component-button-bg-disabled: var(--component-control-bg-disabled);
  --component-button-bg-exciting: var(--component-control-bg-tertiary);
  --component-button-bg-subtle: var(--component-control-bg-secondary);
  --component-button-bg-primary: var(--component-control-bg-primary);
  --component-button-color-disabled: var(--component-control-color-disabled);
  --component-button-color-exciting: var(--component-control-color-primary-inverted);
  --component-button-color-subtle: var(--component-control-color-primary-inverted);
  --component-button-color-link: var(--component-control-color-primary);
  --component-button-color-primary: var(--component-control-color-primary-inverted);
}
