@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .header {
    @apply text-4xl lg:text-6xl;
  }

  .subheader {
    @apply text-3xl lg:text-4xl;
  }

  .subheader2 {
    @apply text-xl lg:text-2xl;
  }

  .body-text {
    @apply text-lg lg:text-xl;
  }

  .section-container {
    @apply mx-6 md:mx-8 lg:mx-16;
  }

  html {
    @apply scroll-smooth;
  }

  .md-content a {
    @apply underline font-bold text-black;
  }
  .md-content p {
    @apply mb-1;
  }
  .md-content ul,
  .md-content ol {
    @apply list-disc ml-4 mb-1;
  }

  /* For Webkit-based browsers (Chrome, Safari and Opera) */
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  /* For IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .whitelist-step-text {
    @apply text-xl @lg:text-2xl leading-6 @lg:leading-8;
  }

  .whitelist-header-text {
    @apply text-center text-4xl @lg:text-5xl lg:text-left mt-8;
  }

  .whitelist-subheader-text {
    @apply mb-1 text-center text-base lg:text-left text-contrast-body mt-2 opacity-60;
  }

  .article-category {
    @apply rounded-3xl text-lg text-[#C11574] bg-[#FDF2FA] px-3 py-1;
  }

  .page-x-pad {
    @apply px-6 md:px-8 lg:px-16;
  }
}

body > #root > div {
  height: 100vh;
}

body {
  margin: 0;
  font-family: 'FH Oscar';
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: 'white';
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*@font-face {
  font-family: "FH Oscar";
  src: url("/fonts/FHOscar-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}*/

@font-face {
  font-family: 'FH Oscar';
  src: url('/fonts/FHOscar-Regular.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: black;
}

@font-face {
  font-family: 'FH Oscar';
  src: url('/fonts/FHOscar-SemiBold.woff2') format('woff2'),
    url('/fonts/FHOscar-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'FH Oscar';
  src: url('/fonts/FHOscar-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FH Oscar';
  src: url('/fonts/FHOscar-Black.woff2') format('woff2'),
    url('/fonts/FHOscar-Black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

:root .twitter-tweet {
  margin: 0 !important;
}

/* Keeps the walletconnect modal above everything when it's opened */
#WEB3_CONNECT_MODAL_ID .web3modal-modal-lightbox {
  z-index: 10000;
}

/* hide the fixed onetrust cookie button, we have one in the footer that's not in the way of the content */
#ot-sdk-btn-floating {
  display: none;
}

/* onetrust button that's in the footer */
button#ot-sdk-btn.ot-sdk-show-settings {
  color: #555;
  border: 1px solid #555;
}

button#ot-sdk-btn.ot-sdk-show-settings:hover {
  color: white;
  background-color: #555;
}

article {
  @apply text-lg leading-none;
}

article h1 {
  @apply text-3xl pt-10 pb-6;
}

article h2 {
  @apply text-2xl pt-8 pb-4;
}

article h3 {
  @apply text-xl pt-6 pb-0;
}

article p {
  @apply pt-4 pb-2 font-normal text-lg text-gray-600 leading-7;
}

article img {
  @apply rounded-2xl w-full;
}

article li {
  @apply ml-10 leading-8 font-normal;
}

article ul li {
  @apply list-disc;
}

article ol li {
  @apply list-decimal;
}

article ol,
ul {
  @apply pt-6;
}

article a {
  @apply underline text-blue-500;
}
